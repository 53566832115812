html,
body {
  height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #666666;
  background-color: #ffffff;
  font-family: 'Open Sans', arial;
}
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
p {
  margin: 0 0 10px;
  font-family: 'Open Sans', sans-serif;
  font-size: 17px;
}
img {
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0;
  color: #2d2d2d;
  font-family: 'Montserrat', sans-serif;
}
h5 {
  font-size: 14px;
}
a {
  color: #802f2c;
  font-family: 'Montserrat', sans-serif;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #802f2c;
  text-decoration: none;
}
figure {
  margin: 0;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
small,
.small {
  font-size: 85%;
}
ul,
li,
a {
  font-family: 'Open Sans', sans-serif;
}
::-moz-selection {
  /* Code for Firefox */
  color: #fff;
  background: #fbc327;
}
::selection {
  color: #fff;
  background: #fbc327;
}
.h150 {
  height: 150px;
}
.pd0 {
  padding: 0;
}
.alert.logged-in-as {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  /* height: 55px; */
  z-index: 9999;
}
.header-spacer {
  height: 145px;
}
body #wrapper {
  margin: 0 auto;
}
body.melina .header_s1 .top-header1 .top-block {
  background-color: #067546;
}
body.melina .navigation .nav_area .navbar .navbar-brand {
  padding: 15px 0;
}
body.melina .navbar-nav > li > .dropdown-menu {
  width: auto !important;
}
/*====================
 Header Area
=====================*/
.top-header {
  background: #802f2c;
  color: #fff;
  font-size: 12px;
  position: relative;
  z-index: 1000;
}
.top-header.melina {
  background: #007646;
}
.top-header a {
  color: #fff;
  text-transform: uppercase;
}
.top-header a:hover {
  text-decoration: underline;
}
.top-header .top-nav ul {
  margin: 0;
  padding: 6px 0;
  list-style: none;
  float: right;
}
.top-header .top-nav ul li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  vertical-align: middle;
}
.top-header .top-nav ul li:after {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 10px;
  background: rgba(255, 255, 255, 0.15);
  content: "";
  margin: 0 10px 0 15px;
}
.top-header .top-nav ul li:last-child:after {
  display: none;
}
.top-header .top-nav ul.dropdown-menu {
  width: 100%;
  border-radius: 0;
  background-color: #802f2c;
  border: none;
}
.top-header .top-nav ul.dropdown-menu li {
  display: block;
  background: #802f2c;
  height: 30px;
}
.top-header .top-nav ul.dropdown-menu li:after {
  height: 0;
}
.top-header .top-nav ul.dropdown-menu li a {
  color: #fff;
}
.top-header .top-nav ul.dropdown-menu li a:hover {
  background: none;
  text-decoration: underline;
}
.top-header .top-right {
  text-align: right;
}
.logged-in-user {
  background: #333;
  color: #fff;
  padding: 10px;
}
.logged-in-user a {
  color: #fff;
}
.header_s1 {
  position: absolute;
  transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
}
.header_s1.navbar-fixed-top {
  position: fixed;
  top: 0;
}
.header_s1 .container > .bg-block {
  background: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.header_s1 .top-header1 .top-block {
  background-color: #802f2c;
  padding-bottom: 7px;
  padding-top: 6px;
}
.header_area {
  color: #636363;
  padding-bottom: 10px;
  padding-top: 10px;
  background: #fff;
}
.navigation .nav .open > a,
.navigation .nav .open > a:focus,
.navigation .nav .open > a:hover {
  background: #fbc327 !important;
}
.navigation .nav_area {
  background: #fff;
}
.navigation .nav_area .navbar {
  min-height: 35px;
  margin-bottom: 0;
}
.navigation .nav_area .navbar ul > li > a {
  color: #333;
  text-transform: uppercase;
  font-size: 15px;
  padding-top: 18px;
  padding-bottom: 18px;
}
.navigation .nav_area .navbar ul > li > a:hover {
  text-decoration: none;
  background: #fbc327;
}
.navigation .nav_area .navbar ul > li > a:focus {
  text-decoration: none;
  background: none;
}
.navigation .nav_area .navbar ul > li.mega-dropdown {
  position: static !important;
}
.navigation .nav_area .navbar ul > li.mega-dropdown.open ul > li > a:hover {
  text-decoration: none;
  background: #fbc327 !important;
}
.navigation .nav_area .navbar ul > li.mega-dropdown.open ul > li > a:focus {
  text-decoration: none;
  background: none;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu {
  padding: 20px 0px;
  width: 100%;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu > li > ul {
  padding: 0;
  margin: 0;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu > li > ul > li {
  list-style: none;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu > li > ul > li > a {
  display: block;
  color: #222;
  padding: 3px 5px;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu > li > ul > li > a:hover {
  text-decoration: none;
  background: #fbc327;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu > li > ul > li > a:focus {
  text-decoration: none;
  background: none;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu .dropdown-header {
  font-size: 18px;
  font-weight: 600;
  color: #802f2c;
  padding: 5px 60px 5px 5px;
  line-height: 30px;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu .carousel-control {
  width: 30px;
  height: 30px;
  top: 0;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu .carousel-control.left {
  right: 30px;
  left: inherit;
}
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu .carousel-control .glyphicon-chevron-left,
.navigation .nav_area .navbar ul > li.mega-dropdown .mega-dropdown-menu .carousel-control .glyphicon-chevron-right {
  font-size: 12px;
  background-color: #fff;
  line-height: 30px;
  text-shadow: none;
  color: #333;
  border: 1px solid #ddd;
}
.navigation .nav_area .navbar ul > li .dropdown-menu li a {
  color: #333;
}
.navigation .nav_area .navbar .navbar-brand {
  height: 55px;
  padding: 15px 25px;
}
.navigation .nav_area .navbar .navbar-brand > img {
  position: absolute;
  top: -40px;
  height: 90px;
}
/*----------------------------------------*/
/*3. Slider area css
/*----------------------------------------*/
.slider-wrap.home-1-slider {
  height: 800px;
  overflow: hidden;
}
.slider-wrap.page-inner-slider {
  height: 600px;
  overflow: hidden;
}
.slider-wrap.page-inner-slider img {
  top: 50%;
  /* 3 */
  -webkit-transform: translate(0, -20%);
  transform: translate(0, -20%);
}
#main-slider {
  height: 650px;
}
#main-slider.staticpage {
  height: 800px;
}
#main-slider .cap-title {
  color: #fff;
}
#main-slider .cap-title span {
  font-size: 50px;
  color: #fff;
}
#main-slider .cap-title span.yellow {
  color: #fbc327;
}
.slider-wrapper {
  position: relative;
}
.slider-wrapper .item.slick-slide {
  position: relative;
}
.slider-wrapper .item.slick-slide img.slide-image {
  position: absolute;
}
.slider-wrapper .carousel.carousel-fade .slick-track,
.slider-wrapper .carousel.carousel-fade .slick-list {
  height: 100%;
}
.slider-wrapper .slider-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.slider-wrapper .page-title-caption {
  position: absolute;
  top: 14%;
  left: 20%;
}
.slider-wrapper .page-title-caption h1 {
  font-size: 50px;
}
.slide-text {
  display: inline-block;
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.cap-title h1,
.cap-title h2 {
  color: #fff;
  font-size: 50px;
  max-width: 80%;
  font-weight: 700;
  margin-left: 0;
  text-transform: uppercase;
}
.cap-title h1 span.yellow,
.cap-title h2 span.yellow {
  color: #fbc327;
}
/*================
about area css
===================*/
.about_area {
  padding: 50px 0 0px;
  margin-bottom: 50px;
  background: #fff;
}
.module-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  margin-bottom: 25px;
  font-weight: normal;
  color: inherit;
}
.module-title > span {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 62px;
  font-weight: bold;
  margin-left: -4px;
  text-transform: uppercase;
}
.module-title.title2 {
  color: #fff;
}
.homework-item {
  padding: 20px;
  border: solid 1px;
  position: relative;
  word-wrap: break-word;
}
.homework-item .homework-content .date {
  font-size: 16px;
  background: #802f2c;
  color: #fff;
  padding: 5px 20px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.homework-item .homework-content .homework-title {
  display: block;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: bold;
  margin-left: -4px;
  text-transform: uppercase;
  margin-top: 40px;
}
.homework-item .homework-content .homework-title span.homework-group-name {
  color: #fbc327;
}
/*================
Post Listing
===================*/
.post-listing .post-item {
  background: #fff;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0px 1px 2px 0px #ddd;
  transition: all 0.3s ease-in-out;
}
.post-listing .post-item:hover {
  box-shadow: -2px 12px 25px 7px #ddd;
}
.page-content {
  background: #fff;
  padding: 20px 0;
  margin: 20px 0;
}
h2 > span.yellow {
  color: #fbc327;
}
.video-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29);
  margin-top: 15px;
  min-height: 400px;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  visibility: visible;
  width: 100%;
  margin-bottom: 40px;
}
.video-wrapper .vidwrap::before {
  color: rgba(247, 247, 247, 0.5);
  content: "";
  cursor: pointer;
  font: 100px/1 FontAwesome;
  left: 45%;
  position: absolute;
  top: 40%;
  transition: all 0.2s ease-in-out 0s;
  z-index: 2;
}
/*================
service area css
===================*/
.service_area {
  text-align: center;
  padding: 50px 0px;
  background: #fff;
}
.service_item {
  padding-top: 30px;
}
.service_item span {
  font-size: 80px;
  transition: 1s;
}
.service_item:hover span {
  color: #02BAFF;
}
.home-3.service_area {
  padding: 30px 0 50px;
}
.iframe1 {
  width: 100%;
  height: 250px;
}
/*================
courses area css
===================*/
.courses_area {
  background: #f7f7f7;
  padding-bottom: 50px;
  padding-top: 80px;
}
.course_item,
.module-item {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29);
  line-height: 1.42857;
  margin-bottom: 30px;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  overflow: hidden;
  padding: 20px 15px;
}
.course_item .teacher-content,
.module-item .teacher-content {
  margin-top: 15%;
  text-align: center;
}
.course_item:hover,
.module-item:hover {
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29), 0 0 13px rgba(43, 59, 93, 0.29);
}
.courses_thumb {
  position: relative;
}
.courses_thumb_text a {
  color: #fff;
}
.courses_thumb_text {
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  position: absolute;
  text-align: center;
  top: 35%;
  width: 100%;
  z-index: 1;
}
.courses_content {
  padding: 20px;
}
.courses_content h2 {
  margin: 10px 0 5px;
  font-weight: normal;
}
.courses_content h2 a {
  color: #2d2d2d;
}
.courses_content p {
  font-weight: normal;
  margin-top: 10px;
}
.text_uppercase {
  text-transform: uppercase;
}
.light-grey {
  background: #f7f7f7 !important;
}
/*================
News area css
===================*/
.news_area {
  background: #fff none repeat scroll 0 0;
  padding: 80px 0;
}
.news_area.homepage-news .single_news_item {
  position: relative;
}
.news_area.homepage-news .single_news_item.small-article {
  height: 270px;
}
.news_area.homepage-news .single_news_item.sticky_post:after {
  content: '\f006';
  font-family: 'FontAwesome';
  position: absolute;
  top: 10px;
  right: 0px;
  height: 35px;
  width: 35px;
  background-color: white;
  color: #f9c326;
  font-size: 18px;
}
.news_area.homepage-news .single_news_item.hero-article .title-url {
  font-size: 18px;
}
.news_area.homepage-news .single_news_item.hero-article p.date {
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.news_area.homepage-news .single_news_item.small-article .news_thumb {
  text-align: center;
}
.news_area.homepage-news .single_news_item.small-article .news_thumb img {
  height: 175px;
  margin: 0 auto;
}
.news-articles-container {
  margin: 50px 0;
}
.news-articles-container > .row {
  border-bottom: solid 1px #ddd;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.news-articles-container > .row .thumbnail {
  border: none;
  height: 190px;
  width: auto !important;
}
.news-search-forms {
  margin-top: 50px;
  border: solid 1px #ddd;
  padding: 10px;
}
.single_news_item {
  background: #ffffff none repeat scroll 0 0;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29);
  line-height: 1.42857;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
  margin-bottom: 15px;
}
.single_news_item.small {
  height: 300px;
}
.news_content {
  padding: 15px;
}
.news_content .date {
  display: none;
}
.date {
  font-size: 11px;
  color: gray;
}
.news_content p {
  font-size: 12px;
  line-height: 18px;
}
.news_content h2 {
  font-size: 14px;
  margin: 0;
}
.single_news_item:hover {
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29), 0 0 13px rgba(43, 59, 93, 0.29);
}
.news_content h2 a {
  color: #2d2d2d;
  transition: 1s;
}
.news_content h2 a:hover {
  color: #802f2c;
}
.read_more {
  text-decoration: none;
  display: inline-block;
  background: #802f2c;
  color: white;
  font-size: 12px;
  padding: 7px 10px;
  text-transform: uppercase;
  color: #fff;
}
.read_more:hover {
  background: #802f2c;
  color: #fff;
}
/*================
footer area css 
===================*/
.footer_area {
  background: #2d2d2d none repeat scroll 0 0;
  padding: 30px 0 60px;
  border-bottom: solid 25px #fff;
}
.single_widget {
  clear: both;
  overflow: hidden;
  margin-bottom: 30px;
}
.widget_thumb > img {
  float: left;
  margin-right: 30px;
}
.widget_content > h2 {
  color: #fff;
  margin-top: 0;
}
.widget_content > p {
  color: #ddd;
}
.widget_content ul li {
  color: #ddd;
}
.widget_content ul li a {
  color: #ddd;
  display: block;
  padding: 2px 0px;
}
.widget_content ul li a:hover {
  color: #802f2c;
}
.footer_bw_image img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray;
  /* IE 6-9 */
  transition: all 0.5s ease;
}
.footer_bw_image img:hover {
  -webkit-filter: none;
  filter: none;
}
.module-ct table {
  border: 0;
}
.wysiwyg-content {
  padding: 50px 0;
  font-size: 17px;
  word-wrap: break-word;
}
.wysiwyg-content p {
  font-size: 17px;
  margin: 0 0 20px;
}
.wysiwyg-content ul {
  padding-left: 40px;
  list-style-type: disc;
  margin: 0 0 20px;
  line-height: 18px;
}
.wysiwyg-content ul li {
  font-size: 17px;
  line-height: 22px;
}
.wysiwyg-content img {
  height: auto !important;
}
.wysiwyg-content .col-md-4 .ge-content .course_item .courses_thumb {
  height: 110px;
}
.wysiwyg-content .col-md-4 .ge-content .course_item .courses_thumb img {
  max-height: 100%;
  max-width: 100%;
}
.wysiwyg-content .table > tbody > tr > td,
.wysiwyg-content .table > tbody > tr > th,
.wysiwyg-content .table > tfoot > tr > td,
.wysiwyg-content .table > tfoot > tr > th,
.wysiwyg-content .table > thead > tr > td,
.wysiwyg-content .table > thead > tr > th {
  border: 1px solid #ddd;
}
.footer_top_left span,
.footer_top_right span {
  color: #fff;
  font-size: 15px;
}
.footer_top_right {
  margin-top: 11px;
  text-align: right;
}
.socials {
  margin-top: 65px;
}
.socials ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.socials ul li {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 5px;
}
.socials ul li a {
  display: block;
  height: 40px;
  width: 145px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  line-height: 38px;
  text-align: center;
  color: #666666;
  transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  background-color: #fff;
}
.socials ul li a i {
  font-size: 25px;
  line-height: 40px;
}
.socials ul li a.isa-fb:hover {
  background-color: #802f2c;
}
.socials ul li a.melina-fb:hover {
  background-color: #067546;
}
.socials ul li a:hover {
  color: #fff;
}
/* Alumni CSS */
.header_spacer {
  height: 135px;
}
.profile-head {
  width: 100%;
  background-color: #ff6600;
  float: left;
  padding: 15px 5px;
}
.profile-head img {
  height: 150px;
  width: 150px;
  margin: 0 auto;
  border: 5px solid #fff;
  border-radius: 5px;
}
.profile-head h5 {
  width: 100%;
  padding: 5px 5px 0px 5px;
  text-align: justify;
  font-weight: bold;
  color: #fff;
  font-size: 25px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.profile-head p {
  width: 100%;
  text-align: justify;
  padding: 0px 5px 5px 5px;
  color: #fff;
  font-size: 17px;
  text-transform: capitalize;
  margin: 0;
}
.profile-head a {
  width: 100%;
  text-align: center;
  padding: 10px 5px;
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
}
.profile-head ul {
  list-style: none;
  padding: 0;
}
.profile-head ul li {
  display: block;
  color: #ffffff;
  padding: 5px;
  font-weight: 400;
  font-size: 15px;
}
.profile-head ul li:hover span {
  color: #000433;
}
.profile-head ul li span {
  color: #ffffff;
  padding-right: 10px;
}
.profile-head ul li a {
  color: #ffffff;
}
.profile-head ul li h6 {
  width: 100%;
  text-align: center;
  font-weight: 100;
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0;
}
.home-2 .courses_thumb {
  width: 100%;
}
.home-2 .courses_content {
  float: right;
  width: 50%;
}
.home-2 .single_teacher_item {
  width: 100%;
  float: none;
  margin-bottom: 30px;
}
.courses_content h2 a:hover {
  color: #802f2c;
}
.programme-presentation {
  background: rgba(0, 0, 0, 0) linear-gradient(to left, #f0f0f0 0%, #f0f0f0 52%, #2d2d2d 48%, #2d2d2d 100%) repeat scroll 0 0;
  padding: 80px;
}
.courses_area.home-2 {
  padding: 80px 0 50px;
}
.input-lg {
  width: 100%;
  border-radius: 0;
  font-size: 18px;
  height: 45px;
  line-height: 1.33;
  padding: 10px 16px;
  border: none;
  color: #000;
}
.btn3 {
  background: #802f2c none repeat scroll 0 0;
  border: medium none;
  border-radius: 0;
  padding: 10px 15px;
  text-transform: uppercase;
}
/*about top banner page css*/
.gray-header {
  background-position: 50% 20%;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  position: relative;
  margin: 0;
}
.gray-header:before {
  bottom: 0;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}
.gray-header .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}
.page-title {
  font-size: 50px;
  margin-bottom: 40px;
  color: #fff;
  padding: 175px 0 100px;
  font-weight: bold;
  text-transform: uppercase;
}
.page-title small {
  color: #ffffff;
  display: block;
}
.title-padding {
  padding: 175px 0 100px;
}
.inner_image_gallery {
  margin-bottom: 75px;
}
.inner_image_gallery h3 {
  position: relative;
  margin-bottom: 50px;
}
.inner_image_gallery h3:after {
  content: '';
  height: 2px;
  background: #fbc327;
  width: 130px;
  position: absolute;
  bottom: -12px;
  left: 37px;
}
/*single news blutin css*/
.news_single_thumb {
  float: left;
  margin: 0 25px 0px 0;
  max-width: 500px;
}
.img_caption {
  background: #d0d0d0;
  padding: 5px;
  text-align: center;
}
.met-box:hover {
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29), 0 0 13px rgba(43, 59, 93, 0.29);
}
/*blog area css*/
.margin-bottom {
  margin-bottom: 30px;
}
.mg0 {
  margin: 0px;
}
.module-inner {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 0;
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29);
  line-height: 1.42857;
  margin-bottom: 20px;
  padding: 20px;
  transition: all 0.2s ease-in-out 0s;
  -webkit-transition: all 0.2s ease-in-out 0s;
  -moz-transition: all 0.2s ease-in-out 0s;
  -o-transition: all 0.2s ease-in-out 0s;
  -ms-transition: all 0.2s ease-in-out 0s;
}
.module-inner:hover {
  box-shadow: 0 1px 2px rgba(43, 59, 93, 0.29), 0 0 13px rgba(43, 59, 93, 0.29);
}
.module-inner.announcements {
  background: #F5D76E;
  padding-bottom: 70px;
}
.module-inner.announcements .announcement-wrapper .announcement-single {
  background: #fff;
  padding: 20px;
  word-wrap: break-word;
  margin-bottom: 30px;
}
/*contact area css*/
.contact_area {
  padding: 90px 0 0px;
}
.form-group {
  clear: both;
  margin-bottom: 25px;
  overflow: hidden;
}
.form-group.has-error {
  overflow: visible;
  position: relative;
}
.form-group.has-error .help-block {
  position: absolute;
  bottom: -30px;
  font-size: 14px;
  background: #fff;
}
/*scrool up*/
#scrollUp {
  border-radius: 0;
  bottom: 20px;
  color: #fbc327;
  font-size: 30px;
  height: 50px;
  line-height: 48px;
  right: 20px;
  text-align: center;
  text-decoration: none;
  width: 50px;
  border: 1px solid #fbc327;
  transition: 1s;
}
#scrollUp:hover {
  border-color: #fbc327;
  background-color: #fff;
  color: #fbc327;
}
.mm-page {
  position: initial;
}
#calendar.fc td {
  background-color: #fff;
  border-color: #ddd;
}
.fc-event {
  cursor: pointer;
}
.helvetilist ul {
  list-style: none;
  width: 100%;
  margin: 0;
  padding: 0;
}
.helvetilist ul li {
  border-bottom: 1px solid #999;
}
.helvetilist ul li:last-child {
  border: none;
}
.helvetilist ul li a {
  color: #999;
  display: block;
  width: 100%;
  min-height: 40px;
  height: auto !important;
  line-height: 40px;
  text-decoration: none;
  font-size: 24px;
  /* transitions and animations */
  transition: font-size 0.3s ease, background-color 1s ease;
}
.helvetilist ul li:hover a {
  font-size: 26px;
  line-height: 40px;
  text-decoration: none;
  color: #999;
  background: #e3e3e3;
  padding-left: 10px;
}
.helvetilist.white a {
  color: #fff;
}
.user_info {
  padding: 10px 0;
  border-bottom: solid 1px #ddd;
}
.user_info .info_title {
  font-weight: 700;
  padding-bottom: 5px;
  text-transform: uppercase;
}
.user_info .info_content {
  font-size: 15px;
}
.filterForm .multiselect-container {
  max-height: 200px;
  overflow: scroll;
}
.media-body .btn-primary {
  color: #000;
  background-color: #fff;
  border-color: #fff;
}
.media-body .nav-pills > li.active > a,
.media-body .nav-pills > li.active > a:focus,
.media-body .nav-pills > li.active > a:hover {
  color: #000;
  background-color: #ddd;
}
.downloadable-files .file-item {
  width: 150px;
  display: inline-block;
  padding: 10px;
  position: relative;
}
.downloadable-files .file-item .file-icon {
  text-align: center;
}
.downloadable-files .file-item .file-icon img {
  height: 64px;
}
.downloadable-files .file-item .file-name {
  height: 60px;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
}
.downloadable-files .file-item:hover {
  background: #F5D76E;
}
.downloadable-files .file-item:hover:after {
  position: absolute;
  top: 5px;
  right: 5px;
  content: '\f019';
  font-family: 'FontAwesome';
  color: #fff;
}
/* NEWS ARTICLES */
.news-list .wrap-post {
  padding: 0px 0px 81px 0px;
}
.news-list .wrap-post .wrap-grid {
  margin-bottom: 30px;
}
.news-list .wrap-post .wrap-grid article.entry {
  height: 350px;
  background-color: #fff;
  margin-bottom: 30px;
  box-shadow: 0px 1px 2px 0px #ddd;
  transition: all 0.3s ease-in-out;
}
.news-list .wrap-post .wrap-grid article.entry:hover {
  box-shadow: -2px 12px 25px 7px #ddd;
}
.news-list .wrap-post .wrap-grid article.entry:last-of-type {
  margin-bottom: 0px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post {
  padding: 22px 15px 0px 0px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post .entry-meta {
  padding-bottom: 15px;
  margin-left: -4px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post .entry-meta span {
  position: relative;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffbf43;
  padding-left: 41px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post .entry-meta span:before {
  position: absolute;
  left: 0;
  top: 6px;
  content: "";
  width: 30px;
  height: 2px;
  background: #ffbf43;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post h3.entry-title {
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  font-weight: 700;
  color: #22272b;
  margin-bottom: 8px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post h3.entry-title a {
  color: #22272b;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post h3.entry-title a:hover {
  color: #ffbf43;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post .entry-content {
  margin-bottom: 0px;
}
.news-list .wrap-post .wrap-grid article.entry .entry-post .entry-content p {
  font-size: 16px;
  line-height: 26px;
  color: #6f787f;
}
.news-list .wrap-post .wrap-grid article.entry .feature-post {
  height: 350px;
}

@media (max-width: 1200px) {
  .header_s1 .top-header1 .top-block {
    width: 100%;
    margin-left: 0;
  }
  .navigation .nav_area .navbar .navbar-brand {
    padding: 0;
  }
  .navigation .nav_area .navbar .navbar-brand img {
    left: -15px;
  }
  .module-title {
    font-size: 24px;
  }
  .module-title > span {
    font-size: 35px;
  }
  .news-list .wrap-post .wrap-grid article.entry .entry-post h3.entry-title {
    margin: 0;
    font-size: 14px;
  }
}
/* Normal desktop :992px. */
@media (min-width: 992px) {
  .header_s1 {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    z-index: 17;
  }
  .teacher_content {
    padding: 25px 10px 0;
  }
  .home-2 .teacher_content {
    padding: 35px 10px 0;
  }
  .menu ul li > a {
    font-size: 13px;
    padding: 16px 14px;
  }
  .footer_top_left span,
  .footer_top_right span {
    font-size: 11px;
  }
  .footer_top_right {
    margin-top: 11px;
  }
  /*mobile menu*/
  .mobile_menu_area {
    display: none;
  }
  .left_sidet1 {
    margin-left: 10%;
  }
  .slide_title {
    font-size: 20px;
  }
  .nav_style li a {
    font-size: 11px;
    padding: 20px 0 0 41px;
  }
  .nav_area {
    display: block;
  }
  .nivo-directionNav a {
    top: 47%;
  }
  .home-2 .search-box input {
    width: 667px;
  }
  .home-4 .teacher_content {
    padding: 9px 26px;
  }
  .home-2 .courses_content {
    padding-bottom: 0px;
  }
  .home-2 .news_content {
    padding: 15px 5px;
  }
}
@media (max-width: 991px) {
  #main-slider,
  #main-slider.staticpage {
    max-height: 520px !important;
  }
  #main-slider .item.slick-slide img.slide-image,
  #main-slider.staticpage .item.slick-slide img.slide-image {
    position: static !important;
    max-width: 100% !important;
  }
  .programme-presentation {
    padding: 0;
    background: none;
  }
  .programme-presentation .module-title {
    margin: 10px 15px 0;
  }
  .programme-presentation .container {
    padding: 0;
  }
  .programme-presentation .container #first-pres-block {
    background: #333;
    padding: 5px 0 10px;
  }
  .programme-presentation .container #second-pres-block {
    padding: 5px 0 10px;
  }
  .news_area {
    padding: 5px 0 0;
  }
  .news_area.homepage-news {
    margin-bottom: 0;
  }
  .about_area {
    margin-bottom: 0;
  }
  /*mobile menu*/
  .mobile_menu_area {
    display: block;
  }
  .mobile_menu_area .mobile-header {
    height: 70px;
  }
  .mobile_menu_area .mobile-header #mobile-menu-logo {
    float: left;
    padding: 0 10px;
  }
  .mobile_menu_area .mobile-header #mobile-menu-trigger {
    float: right;
    padding: 0 10px;
    height: 100%;
    line-height: 85px;
  }
  #mobile-menu {
    background: #fff;
  }
  #mobile-menu .dropdown-menu {
    position: static;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
  #mobile-menu span.caret {
    display: none;
  }
}
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .left_sidet1 {
    margin-left: 7%;
    margin-top: 6%;
  }
  .cap-title h1 {
    font-size: 55px;
  }
  .single_teacher_item {
    float: left;
    position: relative;
    width: 50%;
  }
  .read_more.bmore {
    margin-top: 30px;
  }
  .footer_top_left {
    text-align: center;
  }
  .footer_top_right {
    text-align: center;
  }
  .news_content.news_buletin_pra > h1 {
    font-size: 22px;
    margin: 10px 0 0;
  }
  .blog-title {
    font-size: 16px;
  }
  .blog-title > span {
    font-size: 25px;
  }
  .module-title > span {
    font-size: 51px;
  }
  .home-2 .courses_thumb {
    width: 100%;
  }
  .home-2 .courses_content {
    width: 100%;
  }
  .mobile_menu_area {
    display: block;
  }
  .nav_area,
  .header_s {
    display: none;
  }
  .nav_style li {
    display: inline-block;
    position: relative;
    width: 18.7%;
  }
  .nav_style li a {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    height: 75px;
    padding: 10px 0 0 41px;
  }
  .single_teacher_item {
    width: 50%;
  }
  .module-title > span {
    margin-left: -2px;
  }
}
@media (min-width: 768px) {
  .navbar-fixed-top .navbar-collapse {
    padding-right: 15px;
    padding-left: 15px;
  }
}
@media (max-width: 767px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .container {
    width: 100%;
  }
  /*Slider*/
  .slider-wrapper {
    position: relative;
  }
  .slider-wrapper #main-slider,
  .slider-wrapper #main-slider.staticpage {
    max-height: 520px !important;
  }
  .slider-wrapper .item.slick-slide {
    position: relative;
  }
  .slider-wrapper .item.slick-slide img.slide-image {
    max-width: 100% !important;
  }
  .slider-wrapper .carousel.carousel-fade .slick-track,
  .slider-wrapper .carousel.carousel-fade .slick-list {
    height: 100%;
  }
  .slider-wrapper .slider-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.1);
  }
  .slider-wrapper .page-title-caption {
    position: absolute;
    top: 14%;
    left: 20%;
  }
  .slider-wrapper .page-title-caption h1 {
    font-size: 50px;
  }
  .cap-title h2 {
    font-size: 25px;
  }
  .nav_area,
  .header_s {
    display: none;
  }
  .logo > a {
    display: block;
    text-align: center;
    padding-bottom: 20px;
  }
  .pull-right {
    float: none;
  }
  .pull-right {
    float: left !important;
  }
  .form.pull-right {
    margin-left: 45px;
  }
  .phone_address p {
    margin: 22px 0 0;
  }
  .module-title > span {
    font-size: 35px;
  }
  .single_teacher_item {
    width: 100%;
  }
  .courses_thumb_text {
    top: 45%;
  }
  .courses_thumb img {
    width: 100%;
  }
  .page-title {
    font-size: 31px;
    margin-bottom: 0;
    padding: 10px;
  }
  .news_single_thumb {
    max-width: 100%;
  }
  .news_content.news_buletin_pra > h1 {
    font-size: 14px;
    margin: 10px 0 0;
  }
  .blog-title {
    font-size: 16px;
  }
  .blog-title > span {
    font-size: 25px;
  }
  .fnone {
    text-align: left;
  }
  .pdr0 {
    padding-right: 15px;
  }
  .pdrl {
    padding-left: 15px;
  }
  .footer_bottom_area p {
    text-align: center;
  }
  .home-2 .courses_thumb {
    width: 100%;
  }
  .home-2 .courses_content {
    width: 100%;
  }
  .article-title {
    font-size: 20px;
  }
  .slide_title {
    font-size: 20px;
  }
  .home-2 .teacher_content {
    padding: 33px 18px;
  }
  .left_sidet1 {
    margin-left: 8%;
    margin-top: 7%;
  }
  .cap-title h1 {
    font-size: 18px;
  }
  .cap-dec h2 {
    font-size: 13px;
  }
  .i_box.input_box40 input {
    width: 100%;
  }
  .single_teacher_item:hover .teacher_content {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .module-title > span {
    margin-left: 0px;
  }
  .teacher_content {
    padding: 54px 50px;
  }
  .home-4 .module-title > span {
    font-size: 30px;
  }
  .blogss-title {
    font-size: 37px;
  }
  .slide_title {
    margin-top: 9px;
  }
  .home-4 .slide_title {
    margin-top: 0px;
  }
  .news-list .wrap-post .wrap-grid article.entry {
    height: auto;
    padding-bottom: 15px;
  }
  .news-list .wrap-post .wrap-grid article.entry .col-md-6 {
    padding: 0;
  }
  .news-list .wrap-post .wrap-grid article.entry .entry-post {
    padding: 22px 15px 0px 15px;
  }
  .news-list .wrap-post .wrap-grid article.entry .feature-post {
    height: 240px;
  }
}
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    padding: 0 30px;
  }
  .about_area {
    padding: 0;
  }
  .page-title {
    font-size: 31px;
    margin-bottom: 0;
    padding: 40px 0;
  }
  .left_sidet1 {
    margin-left: 8%;
    margin-top: 7%;
  }
  .cap-title h1 {
    font-size: 30px;
  }
  .cap-dec h2 {
    font-size: 23px;
  }
  .news_thumb img {
    width: 100%;
  }
  .home-2 .teacher_content {
    padding: 110px 43px 43px;
  }
  .teacher_content {
    padding: 134px 50px;
  }
  #main-slider,
  #main-slider.staticpage {
    height: auto !important;
  }
  #main-slider .slide-text,
  #main-slider.staticpage .slide-text {
    margin-top: 10%;
    margin-left: 5%;
  }
  #main-slider .cap-title,
  #main-slider.staticpage .cap-title {
    font-size: 20px;
  }
  #main-slider .cap-title span,
  #main-slider.staticpage .cap-title span {
    font-size: 30px;
  }
}
@media only screen and (max-width: 480px) {
  .row {
    margin: 0;
  }
  .about_area {
    padding: 0;
  }
  .col-xs-12,
  .col-md-12,
  .col-sm-12,
  .col-lg-12 {
    padding: 0;
  }
  .wysiwyg-content {
    padding-top: 10px;
  }
  #main-slider,
  #main-slider.staticpage {
    height: auto !important;
  }
  #main-slider .slide-text,
  #main-slider.staticpage .slide-text {
    margin-top: 10%;
    margin-left: 5%;
  }
  #main-slider .cap-title,
  #main-slider.staticpage .cap-title {
    font-size: 16px;
  }
  #main-slider .cap-title span,
  #main-slider.staticpage .cap-title span {
    font-size: 22px;
  }
}

/*# sourceMappingURL=app.css.map */
